import * as React from 'react';
import { useEffect } from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {useQueryParam, StringParam} from 'use-query-params';
import DOMPurify from 'dompurify';
import Cookies from 'js-cookie';
import { getRapid } from '../../services';

const CommerceExtRedirectPage = ({data}) => {
  const { content } = useStaticQuery(query);
  const [nIdParam] = useQueryParam('n', StringParam);
  const redirectToChromeWebStore = () => {
    // This link will change once Yahoo Shopping is published
    // For now will redirect to Yahoo Home ChromeWebStore extension page
    window.location.replace("https://chromewebstore.google.com/detail/yahoo-homepage/jdanfkhnfpagoijgfmklhgakdicpnfil");
  };
  
    // This code only runs in the browser. Should be called from useEffect or similar API.
    const sanitizeQueryParam = () => {
    if (typeof window === 'undefined' && !nIdParam) return null;
    return DOMPurify.sanitize(nIdParam);
  }
  
  useEffect(() => {
    const sanitizedNcidValue = sanitizeQueryParam();

    getRapid(content.config.rapid.init);
    
    if (sanitizedNcidValue) {      
      Cookies.set('ncid', sanitizedNcidValue, {
        secure: true,
        domain: 'downloads.yahoo.com',
        path: '/commerce',
        sameSite: 'Strict'
      });
    }
    
    redirectToChromeWebStore();
  }, [nIdParam]);

  return <></>
};

export default CommerceExtRedirectPage;


export const query = graphql`
    query {
        content: shoppingExtRedirectJson {
            config {
                rapid {
                    init {
                        spaceid
                        keys {
                            pt
                            pct
                            pg_name
                            ver
                        }
                    }
                }
            }            
        }  
    }
`;
